<template>
  <div class="flix-col-12 flix-col-md-8 flix-col-md-flix-offset-2">
    <form @submit.prevent="setSend">
    <div class="flix-form-group">
      <h2 class="flix-html-h2">Email-Postfach Zugangsdaten</h2>
    </div>
    <div class="flix-form-group">
      <flixIcon id="question-sign" /> Bookingflix bietet Ihnen die Möglichkeit ein eigenes Emailpostfach zu hinterlegen. Dadurch werden alle Terminzusagen, Terminabsagen und Terminerinnerungen über Ihr Email-Postfach geleitet bzw. verschickt. Ihre Kunden sehen Ihre Emailadresse als Absender.
Wir empfehlen diese Funktion für Dienstleister, die viele Termine über Bookingflix.com terminieren. So können Sie das Thema "Spamproblematik beim Emailversand" eigenverantwortlich händeln.
Benutzername, Passwort, etc. erhalten Sie von Ihrem Emailprovider, Domainverwalter oder Webmaster.
    </div>
    <div class="flix-form-group">
      <a href="https://web.bookingflix.com/support/video/20" target="_blank" class="flix-html-a"><flixIcon id="play" /> Erklärvideo ansehen</a>
    </div>
    <div class="flix-form-group">
      <label class="flix-html-label" for="email">{{ $t('message.email') }}</label>
      <input :placeholder="$t('message.email')" id="email" type="email" class="flix-form-control" v-model="save.email" />
    </div>

    <div class="flix-row">
      <div class="flix-col-md-6">
        <div class="flix-form-group">
          <label class="flix-html-label" for="username">{{ $t('message.username') }}</label>
          <input :placeholder="$t('message.username')" autocomplete="username" type="text" id="username" class="flix-form-control" v-model="save.user" />
        </div>
      </div>
      <div class="flix-col-md-6">
        <div class="flix-form-group">
          <label class="flix-html-label" for="password">{{ $t('message.password') }}</label>
          <input :placeholder="$t('message.password')" autocomplete="new-password" type="password" id="password" class="flix-form-control" v-model="save.password" />
        </div>
      </div>
    </div>
    <div class="flix-row">
      <div class="flix-col-md-6">
        <div class="flix-form-group">
          <label class="flix-html-label" for="host">SMTP-Postausgangsserver</label>
          <input placeholder="Postausgangsserver" id="host" type="text" class="flix-form-control" v-model="save.host" />
        </div>
      </div>
      <div class="flix-col-md-2">
        <div class="flix-form-group">
          <label class="flix-html-label" for="port">Port</label>
          <input placeholder="Port" id="port" type="number" class="flix-form-control" v-model="save.port" />
        </div>
      </div>
      <div class="flix-col-md-2">
        <div class="flix-form-group">
          <label class="flix-html-label" for="secure">Encryption</label>
          <select placeholder="Verschlüsselung" id="secure" v-model="save.secure" class="flix-form-control">
            <option value="ssl">ssl</option>
            <option value="tls">tls</option>
            <option value="">---</option>
          </select>
        </div>
      </div>
      <div class="flix-col-md-2">
        <div class="flix-form-group">
          <label class="flix-html-label" for="smtpauth">SMTP-Auth</label>
          <select placeholder="Verschlüsselung" id="smtpauth" v-model="save.smtpauth" class="flix-form-control">
            <option :value="true">ja</option>
            <option value="">nein</option>
          </select>
        </div>
      </div>
    </div>

    <div class="flix-form-group">
      <a href="https://web.bookingflix.com/support/faq/email-2" target="_blank" class="flix-html-a flix-text-info">WICHTIG! Vereinbarung zur Postfachnutzung lesen</a>
    </div>
    <div class="flix-form-group">
      <a href="#" class="flix-html-a" @click.prevent="setAcceptTerms" style="display: flex; align-items: center; justify-content: flex-start; gap: 5px">
        <flixIcon id="unchecked" v-if="!terms" />
        <flixIcon id="check" v-else /> Ich stimme der Vereinbarung zur eigenen Postfachnutzung zu.</a>
    </div>
    <div class="flix-form-group" v-if="!checkStatus()">
      <a href="#" class="flix-btn flix-btn-default" :class="{'flix-disabled': !terms}" @click.prevent="setTest()">Testemail an {{ variables.user.email }} senden</a>
    </div>
    <div class="flix-form-group" v-else>
      <button class="flix-btn flix-btn-success" :class="{'flix-disabled': !terms}">Einstellungen speichern</button>
    </div>
    <status v-if="status" :key="updateKey" :status="status" />
    </form>
  </div>
</template>

<script>
export default {
  components: {
    status () { return import('./status') }
  },
  props: {
    callback: Function
  },
  data () {
    return {
      updateKey: false,
      status: false,
      terms: false,
      variables: this.$getUserVariables(),
      save: {
        email: '',
        host: '',
        user: '',
        password: '',
        port: '465',
        secure: 'ssl',
        ishtml: true,
        smtpauth: true
      }
    }
  },
  computed: {

  },
  mounted () {
  },
  methods: {
    checkStatus () {
      if (!this.status) {
        return false
      }
      if (this.status.toLowerCase().indexOf('error') === -1) {
        return true
      }
      return false
    },
    setTest () {
      if (!this.terms) {
        return false
      }
      this.$flix_post({
        url: 'user_smtp/test',
        data: {
          user: this.variables.user.md5_id,
          outbox: this.save
        },
        callback: function (ret) { this.updateKey = new Date().getTime(); this.status = ret.data[1] }.bind(this)
      })
    },
    setSave () {
      this.$flix_post({
        url: 'user_smtp/save',
        data: {
          user: this.variables.user.md5_id,
          outbox: this.save
        },
        callback: function (ret) { this.updateKey = new Date().getTime(); this.status = this.$t('message.successfullSaved'); this.callback(this.save.email) }.bind(this)
      })
    },
    setSend () {
      if (!this.terms) {
        return false
      }
      this.setSave()
    },
    setAcceptTerms () {
      this.terms = !this.terms
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
